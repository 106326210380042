import React from 'react';
import { Container, Typography, Box } from '@mui/material';


const LP: React.FC = () => {

    const currentYear = new Date().getFullYear();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <header style={headerStyle}>
                <Container maxWidth="lg" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '1.5rem', marginLeft: '10px' }}>Quick APP</div>
                    </div>
                    <nav>
                        <ul style={navListStyle}>
                            {/* <li style={navItemStyle}><a href="/" style={navLinkStyle}>Home</a></li>
                            <li style={navItemStyle}><a href="/about" style={navLinkStyle}>About</a></li>
                            <li style={navItemStyle}><a href="/" style={navLinkStyle}>Services</a></li>
                            <li style={navItemStyle}><a href="/" style={navLinkStyle}>Contact</a></li> */}
                        </ul>
                    </nav>
                </Container>
            </header>

            {/* Main Content */}
            <Box component="main" sx={{ flexGrow: 1, mt: 8, py: 4 }}>

            </Box>

            {/* Footer */}
            <Box component="footer" sx={{ py: 4, backgroundColor: '#f5f5f5' }}>
                <Container>
                    <Typography variant="body2" color="textSecondary" align="center">
                        &copy; {currentYear} Quick, Inc. All Rights Reserved.
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};

const headerStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #ddd',
    zIndex: 1000,
    padding: '10px 0',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
};

const navListStyle: React.CSSProperties = {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    gap: '20px'
};

export default LP;