import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';

interface Hospital {
    id: number;
    name: string;
    address: string;
    phone: string;
    description: string;
    departments: string[];  // 診察科の情報を追加
    emergencyAvailable: boolean;  // 救急外来の有無を示すフラグ
    openingHours: string;  // 診察可能時間
}

const hospitals: Hospital[] = [
    {
        id: 1,
        name: "筑波大学附属病院",
        address: "茨城県つくば市天王台1-1-1",
        phone: "029-853-3095",
        description: "つくば市の大規模な大学病院。救急外来あり。",
        departments: ["脳神経外科", "循環器内科", "内分泌・代謝科"],
        emergencyAvailable: true,
        openingHours: "8:30 - 17:00",
    },
    {
        id: 2,
        name: "つくばメディカルセンター病院",
        address: "茨城県つくば市吾妻1-3-1",
        phone: "029-851-3511",
        description: "地域医療に貢献する総合病院。脳神経外科あり。",
        departments: ["脳神経外科", "整形外科", "救急科"],
        emergencyAvailable: true,
        openingHours: "9:00 - 17:30",
    },
    {
        id: 3,
        name: "筑波記念病院",
        address: "茨城県つくば市要1187-299",
        phone: "029-864-1212",
        description: "筑波周辺の総合病院。救急外来は24時間対応。",
        departments: ["脳神経外科", "消化器内科", "心臓外科"],
        emergencyAvailable: true,
        openingHours: "8:30 - 17:00",
    },
];

const Hospitals: React.FC = () => {
    const currentYear = new Date().getFullYear();
    const currentTime = new Date();

    const isWithinOpeningHours = (openingHours: string) => {
        const [openHour, openMinute] = openingHours.split(" - ")[0].split(":").map(Number);
        const [closeHour, closeMinute] = openingHours.split(" - ")[1].split(":").map(Number);

        const openingTime = new Date();
        openingTime.setHours(openHour, openMinute);

        const closingTime = new Date();
        closingTime.setHours(closeHour, closeMinute);

        return currentTime >= openingTime && currentTime <= closingTime;
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Header */}
            <header style={headerStyle}>
                <Container maxWidth="lg" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '1.5rem', marginLeft: '10px' }}>Quick APP</div>
                    </div>
                </Container>
            </header>

            {/* Main Content */}
            <Box component="main" sx={{ flexGrow: 1, mt: 8, py: 4 }}>
                <Container>
                    <Typography variant="h4" gutterBottom>
                        <b>診断結果</b>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        ・脳梗塞の可能性があります。可能な限り早急に救急車を呼んでください。
                    </Typography>

                    <Typography variant="h4" gutterBottom>
                        近隣の病院リスト
                    </Typography>

                    <Grid container spacing={4}>
                        {hospitals.map((hospital) => (
                            <Grid item xs={12} sm={6} md={4} key={hospital.id}>
                                <Card>
                                    <CardActionArea component={Link} to={`/hospital/${hospital.id}`}>
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom>
                                                {hospital.name}
                                            </Typography>
                                            <Typography variant="body1">
                                                住所: {hospital.address}
                                            </Typography>
                                            <Typography variant="body1">
                                                電話番号: {hospital.phone}
                                            </Typography>
                                            <Typography variant="body2">
                                                診察科: {hospital.departments.join(", ")}
                                            </Typography>
                                            <Typography variant="body2">
                                                救急外来: {hospital.emergencyAvailable ? "あり" : "なし"}
                                            </Typography>
                                            <Typography variant="body2">
                                                診療時間: {hospital.openingHours}
                                            </Typography>
                                            <Typography variant="body2" color={isWithinOpeningHours(hospital.openingHours) ? "green" : "red"}>
                                                {isWithinOpeningHours(hospital.openingHours) ? "診療可能" : "診療時間外"}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>

            {/* Footer */}
            <Box component="footer" sx={{ py: 4, backgroundColor: '#f5f5f5' }}>
                <Container>
                    <Typography variant="body2" color="textSecondary" align="center">
                        &copy; {currentYear} Quick, Inc. All Rights Reserved.
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};

// Custom CSS for header
const headerStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #ddd',
    zIndex: 1000,
    padding: '10px 0',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
};

export default Hospitals;