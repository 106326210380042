import React, { useState, useEffect } from 'react';
import { Container, Paper, Button, Typography, Box } from '@mui/material';

const HearRate: React.FC = () => {
    const [time, setTime] = useState(0);
    const [isRunning, setIsRunning] = useState(false);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (isRunning) {
            timer = setInterval(() => {
                setTime((prevTime) => prevTime + 1);
            }, 1000);
        } else if (!isRunning && time !== 0) {
            if (timer) clearInterval(timer);
        }
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [isRunning]);

    const startStopwatch = () => setIsRunning(true);
    const stopStopwatch = () => setIsRunning(false);
    const resetStopwatch = () => {
        setIsRunning(false);
        setTime(0);
    };
    const currentYear = new Date().getFullYear();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <header style={headerStyle}>
                <Container maxWidth="lg" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '1.5rem', marginLeft: '10px' }}>Quick APP</div>
                    </div>
                    <nav>
                        <ul style={navListStyle}></ul>
                    </nav>
                </Container>
            </header>

            {/* Main Content */}
            <Box component="main" sx={{ flexGrow: 1, mt: 8, py: 4 }}>
                <Container>
                    {/* ストップウォッチ表示部分 */}
                    <Paper sx={{ p: 3, mb: 4, textAlign: 'center' }}>
                        <Typography variant="h5" gutterBottom>
                            心拍数測定用ストップウォッチ
                        </Typography>
                        <Typography variant="h3" gutterBottom>
                            {Math.floor(time / 60)}分 {time % 60}秒
                        </Typography>
                        <Button variant="contained" color="primary" onClick={startStopwatch} sx={{ m: 1 }} disabled={isRunning}>
                            スタート
                        </Button>
                        <Button variant="contained" color="secondary" onClick={stopStopwatch} sx={{ m: 1 }} disabled={!isRunning}>
                            ストップ
                        </Button>
                        <Button variant="outlined" onClick={resetStopwatch} sx={{ m: 1 }}>
                            リセット
                        </Button>
                    </Paper>
                </Container>
            </Box>

            <Box component="footer" sx={{ py: 4, backgroundColor: '#f5f5f5' }}>
                <Container>
                    <Typography variant="body2" color="textSecondary" align="center">
                        &copy; {currentYear} Quick, Inc. All Rights Reserved.
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};

const headerStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #ddd',
    zIndex: 1000,
    padding: '10px 0',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
};

const navListStyle: React.CSSProperties = {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    gap: '20px'
};

export default HearRate;