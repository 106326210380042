import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LP from './components/TopPage';
import Admin from './components/Admin';
import Monshin from './components/Monshin';
import HearRate from './components/Heartrate';
import Hospitals from './components/Hospitals';
import HospitalDetail from './components/Hospital';
import Taxis from './components/Taxis';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LP />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/heartrate/:ulid" element={<HearRate />} />
        <Route path="/questionnaire/:ulid" element={<Monshin />} />
        <Route path="/hospitals" element={<Hospitals />} />
        <Route path="/hospital/:id" element={<HospitalDetail />} />
        <Route path="/taxis" element={<Taxis />} />
      </Routes>
    </Router>
  );
};

export default App;