import React, { useEffect } from 'react';
import { Container, Typography, Box } from '@mui/material';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const mapContainerStyle = {
    width: '100%',
    height: '600px',
};

// タクシーの位置をランダムに生成する関数
const generateRandomPositions = (center: { lat: number; lng: number }, radius: number, count: number): [number, number][] => {
    const positions: [number, number][] = [];
    for (let i = 0; i < count; i++) {
        const angle = Math.random() * 2 * Math.PI; // ランダムな角度
        const distance = Math.random() * radius; // ランダムな半径
        const newLat = center.lat + (distance * Math.cos(angle)) / 111; // 緯度1度 ≈ 111km
        const newLng = center.lng + (distance * Math.sin(angle)) / (111 * Math.cos(center.lat * (Math.PI / 180))); // 経度の距離は緯度によって異なる
        positions.push([newLat, newLng]);
    }
    return positions;
};

const Taxis: React.FC = () => {
    const center = { lat: 36.078157, lng: 140.110102 }; // つくば周辺の中心座標
    const radius = 2; // 半径10km以内にタクシーを配置
    const taxiPositions = generateRandomPositions(center, radius, 5); // 5つのランダムな位置を生成

    useEffect(() => {
        const map = L.map('map').setView([center.lat, center.lng], 14);

        // OSM タイルを追加
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(map);

        // カスタムアイコンを作成
        const taxiIcon = L.icon({
            iconUrl: '/logo10.png', // カスタム画像のパス
            iconSize: [50, 50], // アイコンのサイズ
            iconAnchor: [25, 50], // アイコンのアンカー（下部中央を基準）
            popupAnchor: [0, -50], // ポップアップの表示位置
        });

        // ランダムに生成したタクシーの位置にピンを配置
        taxiPositions.forEach((position) => {
            L.marker(position, { icon: taxiIcon }).addTo(map).bindPopup('タクシー');
        });

        return () => {
            map.remove();
        };
    }, [taxiPositions]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Header */}
            <header style={headerStyle}>
                <Container maxWidth="lg" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '1.5rem', marginLeft: '10px' }}>Quick APP - タクシー検索</div>
                    </div>
                </Container>
            </header>

            {/* Main Content */}
            <Box component="main" sx={{ flexGrow: 1, mt: 8, py: 4 }}>
                <Container>
                    <Typography variant="h4" gutterBottom>
                        付近のタクシーを検索
                    </Typography>

                    {/* OSMの地図を表示 */}
                    <Box id="map" sx={{ width: '100%', height: '600px', border: '1px solid black' }}></Box>
                </Container>
            </Box>

            {/* Footer */}
            <Box component="footer" sx={{ py: 4, backgroundColor: '#f5f5f5' }}>
                <Container>
                    <Typography variant="body2" color="textSecondary" align="center">
                        &copy; {new Date().getFullYear()} Quick, Inc. All Rights Reserved.
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};

const headerStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #ddd',
    zIndex: 1000,
    padding: '10px 0',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
};

export default Taxis;