import React from 'react';
import { Container, Grid, Box, Typography, TextField, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ulid } from 'ulid';

const baseUrl = "https://app.quick119.co.jp";
const vitalLinks = {
    呼吸数: baseUrl + "/heartrate",
    脈拍: baseUrl + "/heartrate",
    血中酸素濃度: baseUrl + "/heartrate",
};
const monshinLinks = {
    問診リンク: baseUrl + "/questionnaire",
};

const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    alert(`Copied to clipboard: ${text}`);
};

const Admin: React.FC = () => {

    const generateUlid = () => ulid();
    const generatedUlid = generateUlid();
    const currentYear = new Date().getFullYear();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <header style={headerStyle}>
                <Container maxWidth="lg" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '1.5rem', marginLeft: '10px' }}>Quick APP(管理者モード）</div>
                    </div>
                </Container>
            </header>

            <Box component="main" sx={{ flexGrow: 1, mt: 12, py: 4, ml: 1, mr: 1 }}>
                <Typography variant="h5" gutterBottom>
                    <b>問診用リンクを生成</b>
                </Typography>
                <Grid container spacing={3}>
                    {Object.entries(monshinLinks).map(([vitalName, link], index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box
                                sx={{
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    padding: '16px',
                                    textAlign: 'center',
                                    backgroundColor: '#f9f9f9',
                                }}
                            >
                                <Typography variant="h6" gutterBottom>
                                    {vitalName}
                                </Typography>

                                {/* テキストフィールドにリンクを表示 */}
                                <TextField
                                    variant="outlined"
                                    value={link + "/" + generatedUlid}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <IconButton onClick={() => copyToClipboard(link + "/" + generatedUlid)}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        )
                                    }}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Main Content */}
            <Box component="main" sx={{ flexGrow: 1, mt: 5, py: 4, ml: 1, mr: 1 }}>
                <Typography variant="h5" gutterBottom>
                    <b>バイタル測定リンクを生成</b>
                </Typography>
                <Grid container spacing={3}>
                    {Object.entries(vitalLinks).map(([vitalName, link], index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Box
                                sx={{
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    padding: '16px',
                                    textAlign: 'center',
                                    backgroundColor: '#f9f9f9',
                                }}
                            >
                                <Typography variant="h6" gutterBottom>
                                    {vitalName}
                                </Typography>

                                {/* テキストフィールドにリンクを表示 */}
                                <TextField
                                    variant="outlined"
                                    value={link + "/" + generatedUlid}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <IconButton onClick={() => copyToClipboard(link + "/" + generatedUlid)}>
                                                <ContentCopyIcon />
                                            </IconButton>
                                        )
                                    }}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Footer */}
            <Box component="footer" sx={{ py: 4, backgroundColor: '#f5f5f5' }}>
                <Container>
                    <Typography variant="body2" color="textSecondary" align="center">
                        &copy; {currentYear} Quick, Inc. All Rights Reserved.
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};

// Custom CSS for header
const headerStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #ddd',
    zIndex: 1000,
    padding: '10px 0',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
};

export default Admin;