import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, Box, Paper, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

const Monshin: React.FC = () => {
    const { ulid } = useParams();
    const navigate = useNavigate(); // ページ遷移用
    const currentYear = new Date().getFullYear();

    // ダミーの問診質問と選択肢のデータ
    const questions = [
        {
            question: "息はできますか？",
            options: ["はい", "いいえ"]
        },
        {
            question: "出血や怪我はありますか？",
            options: ["出血がある", "怪我がある", "どちらもない"]
        },
        {
            question: "痛みはどこにありますか？",
            options: ["胸", "頭", "腹部", "その他", "痛みはない"]
        },
        {
            question: "呼吸が苦しいですか？",
            options: ["はい", "いいえ"]
        },
        {
            question: "意識が朦朧としていますか？",
            options: ["はい", "いいえ", "わからない"]
        },
        {
            question: "現在何か薬を服用していますか？",
            options: ["はい", "いいえ", "わからない"]
        },
        {
            question: "意識はありますか？",
            options: ["はい", "いいえ", "わからない"]
        },
    ];

    // 現在の質問のインデックスを管理
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [chatLog, setChatLog] = useState<Array<{ speaker: string, message: string }>>([]);

    // チャットボックスのスクロールを管理するためのref
    const chatBoxRef = useRef<HTMLDivElement>(null);

    // チャットログが更新されるたびに、チャットボックスを最下部までスクロール
    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [chatLog]);

    // 質問に対する選択肢をクリックしたときの処理
    const handleOptionClick = (option: string) => {
        const currentQuestion = questions[currentQuestionIndex].question;

        // 選択肢をチャットログに追加
        setChatLog(prevLog => [
            ...prevLog,
            { speaker: "あなた", message: option },
            { speaker: "119", message: `次の質問: ${currentQuestion}` }
        ]);

        // 「意識はありますか？」の質問で「いいえ」が選ばれた場合、/code_redに遷移
        if (currentQuestion === "意識はありますか？" && option === "いいえ") {
            navigate('/hospitals');
            return;
        }

        // 次の質問に進む
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Header */}
            <header style={headerStyle}>
                <Container maxWidth="lg" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '1.5rem', marginLeft: '10px' }}>Quick APP</div>
                    </div>
                </Container>
            </header>

            {/* Main Content */}
            <Box component="main" sx={{ flexGrow: 1, mt: 8, py: 4 }}>
                <Container maxWidth="sm">
                    {/* チャットログ表示 */}
                    <Box
                        ref={chatBoxRef}
                        sx={{
                            mb: 4,
                            height: '400px',
                            overflowY: 'scroll',
                            p: 2,
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            backgroundColor: '#f9f9f9'
                        }}
                    >
                        {chatLog.map((log, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: log.speaker === "あなた" ? 'flex-end' : 'flex-start',
                                    mb: 2,
                                }}
                            >
                                <Paper
                                    sx={{
                                        p: 2,
                                        maxWidth: '60%',
                                        backgroundColor: log.speaker === "あなた" ? '#e0f7fa' : '#f0f0f0',
                                    }}
                                >
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        {log.speaker}:
                                    </Typography>
                                    <Typography variant="body2">{log.message}</Typography>
                                </Paper>
                            </Box>
                        ))}
                    </Box>

                    {/* 現在の質問 */}
                    <Paper sx={{ p: 3, mb: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            {questions[currentQuestionIndex].question}
                        </Typography>

                        {/* 選択肢の表示 */}
                        {questions[currentQuestionIndex].options.map((option, index) => (
                            <Button
                                key={index}
                                variant="contained"
                                color="primary"
                                sx={{ m: 1 }}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option}
                            </Button>
                        ))}
                    </Paper>
                </Container>
            </Box>

            {/* Footer */}
            <Box component="footer" sx={{ py: 4, backgroundColor: '#f5f5f5' }}>
                <Container>
                    <Typography variant="body2" color="textSecondary" align="center">
                        &copy; {currentYear} Quick, Inc. All Rights Reserved.
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};

// Custom CSS for header
const headerStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #ddd',
    zIndex: 1000,
    padding: '10px 0',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
};

export default Monshin;