import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Paper, Button } from '@mui/material';

interface Hospital {
    id: number;
    name: string;
    address: string;
    phone: string;
    description: string;
    departments: string[];  // 診察科の情報
    emergencyAvailable: boolean;  // 救急外来の有無
    openingHours: string;  // 診察時間
}

const hospitals: Hospital[] = [
    {
        id: 1,
        name: "筑波大学附属病院",
        address: "茨城県つくば市天王台1-1-1",
        phone: "029-853-3095",
        description: "つくば市の大規模な大学病院。救急外来あり。",
        departments: ["脳神経外科", "循環器内科", "内分泌・代謝科"],
        emergencyAvailable: true,
        openingHours: "8:30 - 17:00",
    },
    {
        id: 2,
        name: "つくばメディカルセンター病院",
        address: "茨城県つくば市吾妻1-3-1",
        phone: "029-851-3511",
        description: "地域医療に貢献する総合病院。脳神経外科あり。",
        departments: ["脳神経外科", "整形外科", "救急科"],
        emergencyAvailable: true,
        openingHours: "9:00 - 17:30",
    },
    {
        id: 3,
        name: "筑波記念病院",
        address: "茨城県つくば市要1187-299",
        phone: "029-864-1212",
        description: "筑波周辺の総合病院。救急外来は24時間対応。",
        departments: ["脳神経外科", "消化器内科", "心臓外科"],
        emergencyAvailable: true,
        openingHours: "8:30 - 17:00",
    },
];

const HospitalDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();  // URLのパラメータから病院IDを取得
    const navigate = useNavigate();  // ページ遷移に使用
    const hospital = hospitals.find(h => h.id === Number(id));

    if (!hospital) {
        return <Typography variant="h6">病院が見つかりませんでした。</Typography>;
    }

    const handleTaxiSearch = () => {
        navigate('/taxis');  // タクシーを探すボタンが押されたときに/taxisに遷移
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Header */}
            <header style={headerStyle}>
                <Container maxWidth="lg" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '1.5rem', marginLeft: '10px' }}>Quick APP</div>
                    </div>
                </Container>
            </header>

            {/* Main Content */}
            <Box component="main" sx={{ flexGrow: 1, mt: 8, py: 4 }}>
                <Container>
                    <Paper sx={{ p: 4 }}>
                        {/* 病院の画像 */}
                        <Box sx={{ textAlign: 'center', mb: 2 }}>
                            <img src="/hospital_1.jpg" alt={`${hospital.name}の画像`} style={{ maxWidth: '100%', height: 'auto' }} />
                        </Box>

                        {/* 病院の情報 */}
                        <Typography variant="h4" gutterBottom>
                            {hospital.name}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            住所: {hospital.address}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            電話番号: {hospital.phone}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {hospital.description}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            診察科: {hospital.departments.join(", ")}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            救急外来: {hospital.emergencyAvailable ? "あり" : "なし"}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            診療時間: {hospital.openingHours}
                        </Typography>

                        {/* タクシーを探すボタン */}
                        <Box sx={{ mt: 4, textAlign: 'center' }}>
                            <Button variant="contained" color="primary" onClick={handleTaxiSearch}>
                                タクシーを探す
                            </Button>
                        </Box>
                    </Paper>
                </Container>
            </Box>

            {/* Footer */}
            <Box component="footer" sx={{ py: 4, backgroundColor: '#f5f5f5' }}>
                <Container>
                    <Typography variant="body2" color="textSecondary" align="center">
                        &copy; {new Date().getFullYear()} Quick, Inc. All Rights Reserved.
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};

const headerStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #ddd',
    zIndex: 1000,
    padding: '10px 0',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
};

export default HospitalDetail;